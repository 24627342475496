//headshots format ->initals+hs (headshot), except alumni, 
//alumni
// founding
import jeanchoiheadshot from './images/BrotherHeadshots/Alumni/jeanchoiheadshot.jpg';
import justintranheadshot from './images/BrotherHeadshots/Alumni/justintranheadshot.jpg';
import michaelyiuheadshot from './images/BrotherHeadshots/Alumni/michaelyiuheadshot.jpg';
import audreyhoangheadshot from './images/BrotherHeadshots/Alumni/audreyhoangheadshot.jpg';
import tylerlinheadshot from './images/BrotherHeadshots/Alumni/tylerlinheadshot.jpg';
import emmastrambergheadshot from './images/BrotherHeadshots/Alumni/emmastrambergheadshot.jpg';
import colemarinheadshot from './images/BrotherHeadshots/Alumni/colemarinheadshot.jpg';
import ashparasaheadshot from './images/BrotherHeadshots/Alumni/ashparasaheadshot.jpg';
import spencerflynnheadshot from './images/BrotherHeadshots/Alumni/spencerflynnheadshot.jpg';
import khanhthyleheadshot from './images/BrotherHeadshots/Alumni/khanhthyleheadshot.jpg';
import joeylombardoheadshot from './images/BrotherHeadshots/Alumni/joeylombardoheadshot.jpg';
import anushreeparmarheadshot from './images/BrotherHeadshots/Alumni/anushreeparmarheadshot.jpg';
import melvintabiosheadshot from './images/BrotherHeadshots/Alumni/melvintabiosheadshot.jpg';
import vincentchenheadshot from './images/BrotherHeadshots/Alumni/vincentchenheadshot.jpg';

//alpha
import gabrielhyunheadshot from './images/BrotherHeadshots/Alumni/gabrielhyunheadshot.jpg';
import jordanrossheadshot from './images/BrotherHeadshots/Alumni/jordanrossheadshot.jpg';
import sabrinanguyenheadshot from './images/BrotherHeadshots/Alumni/sabrinanguyenheadshot.jpg';
import carolinephamheadshot from './images/BrotherHeadshots/Alumni/carolinephamheadshot.jpg';
import jbrhs from './images/BrotherHeadshots/Alpha/jadonbradfordheadshot.jpg';
import adhs from './images/BrotherHeadshots/Alpha/alexisdigregorioheadshot.jpg';
import shhs from './images/BrotherHeadshots/Alpha/skylerhanheadshot.jpg';
import clhs from './images/BrotherHeadshots/Alpha/carloslopezheadshot.jpg';
//beta
import nmhs from './images/BrotherHeadshots/Beta/noellemarroquin.png';
import xphs from './images/BrotherHeadshots/Beta/xavierapons.png';
import trhs from './images/BrotherHeadshots/Beta/tanarisley.png';
//import srhs from './images/BrotherHeadshots/Beta/shannonrobinson.jpg';
import bshs from './images/BrotherHeadshots/Beta/bellashaffer.png';
import rwhs from './images/BrotherHeadshots/Beta/reesewheeler.JPG';
//Gamma
import nghs from './images/BrotherHeadshots/Gamma/NickGhaffarianHeadshot.png';

//beta
//soren(?)

//active

//delta
import jbuhs from './images/BrotherHeadshots/Delta/JasmineBuenoHeadshot.jpeg';
import achs from './images/BrotherHeadshots/Delta/AbbyCarterHeadshot.jpg';
import rfhs from './images/BrotherHeadshots/Delta/RaquelFujitaHeadshot.png';
import vghs from './images/BrotherHeadshots/Delta/VanessaGuzmanHeadshot.jpeg';
import sphs from './images/BrotherHeadshots/Delta/SakikoPizzornoHeadshot.jpg';
import cuhs from './images/BrotherHeadshots/Delta/CadenUpsonHeadshot.jpg';
import evhs from './images/BrotherHeadshots/Delta/EmilyVuongHeadshot.jpg';
import jzhs from './images/BrotherHeadshots/Delta/JanetZavala-AlborHeadshot.jpg';
//Epsilon
import sahs from './images/BrotherHeadshots/Epsilon/ShafiqAmatHeadshot.png';
//import jdhs from './images/BrotherHeadshots/Epsilon/JackDevereuxHeadshot.JPEG';
import dghs from './images/BrotherHeadshots/Epsilon/DominiqueGiangHeadshot.jpg';
import anhs from './images/BrotherHeadshots/Epsilon/AvaNgoHeadshot.jpg';
import iphs from './images/BrotherHeadshots/Epsilon/IzabellaPargaHeadshot.jpeg';
import aphs from './images/BrotherHeadshots/Epsilon/AidenPhanHeadshot.png';
import prhs from './images/BrotherHeadshots/Epsilon/PaigeReadHeadshot.jpg';
import erhs from './images/BrotherHeadshots/Epsilon/EvanRussellHeadshot.png';
import jyhs from './images/BrotherHeadshots/Epsilon/JodiYamaneHeadshot.jpeg';
//Zeta
import salhs from './images/BrotherHeadshots/Zeta/SuhanthAlluriHeadshot.png';
import jnhs from './images/BrotherHeadshots/Zeta/JasmineNgHeadshot.png';
import mshs from './images/BrotherHeadshots/Zeta/MaxwellSilverHeadshot.png';
import wths from './images/BrotherHeadshots/Zeta/WillTollHeadshot.jpeg';


//profile pictures

//resumes
import rwr from './images/BrotherResumes/reesewheelerresume.pdf'
import jyr from './images/BrotherResumes/JodiYamaneresume.pdf';

// empty experience object to copy and paste {year: '', company: '', position: ''},
export const Alum = [ // huge array containing all the brother objects (make use of VS Code 'fold' feature for an easier life)
  { // Jean Choi
    id: '0',
    gender: 'f',
    name: 'Jean Choi',
    major: ['Statistics', 'STAT'],
    minor: '',
    headshot: jeanchoiheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/jean-choi-cp/',
    resume: 'https://drive.google.com/file/d/1eGFNyyyn5ImUnhfeAfhfYBu2RB983W1K/view?usp=sharing',
    campusInvolvement: 'SLO Hacks, Data Science Fellowship, KASA, Stats Club, Google Developer Club, Spec',
    experience: [
      {year: '2020', company: 'National Science Foundation', position: 'Data Science Fellow'},
      {year: '2020', company: 'Cal Poly SLO Software and Computer Engineering Department', position: 'Data Analytics Research Assistant'},
      {year: '2019-2020', company: 'Cal Fresh', position: 'Data Analytics Intern'},
    ],
    interestHobbies: 'Taekwondo, Soccer, Baking, Traveling',
    funFact: 'I am a second degree black belt in taekwondo'
  },
  { //Justin Tran
    id: '1',
    gender: 'm',
    name: 'Justin Tran',
    major: ['Computer Science', 'CSC'],
    minor: '',
    headshot: justintranheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/justin-tran-a4848a183/',
    resume: 'https://drive.google.com/file/d/1sw1ePl9SVEUixRaOEe3QXkfBuLsMB3yC/view?usp=sharing',
    campusInvolvement: 'Information Systems Association, Computer Engineering Society, Korean American Student Association',
    experience: [
      {year: '2020', company: 'Exploratorium', position: 'Digital Content Creator'},
      {year: '2020', company: 'Trees', position: 'Product Design Intern'},
    ],
    interestHobbies: 'Spending too much time on Spotify & being bad at basketball :(',
    funFact: 'Bladee turned the crib to the club'
  },
  { // Michael Yiu
    id: '2',
    gender: 'm',
    name: 'Michael Yiu',
    major: ['Mechanical Engineering', 'ME'],
    minor: '',
    headshot: michaelyiuheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/michaelw-yiu/',
    resume: 'https://drive.google.com/file/d/1eNTb3Nq6mYdeTvNIKvzZAlJpFo-x7rR4/view?usp=sharing',
    campusInvolvement: 'American Society of Mechanical Engineers, Northrop Grumman Collaboration, CP Scholars, KASA',
    experience: [
      {year: '2019-Present', company: 'Cal Poly Scholars', position: 'Events Coordinator'},
    ],
    interestHobbies: 'Cooking, Knitting, Picnicking, Watching the sunset at a beach',
    funFact: 'I originally wanted to be a history major!'
  },
  { // Audrey Hoang
    id: '3',
    gender: 'f',
    name: 'Audrey Hoang',
    major: ['Architectural Engineering', 'ARCE'],
    minor: '',
    headshot: audreyhoangheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/audrey-h-4249a6139/',
    resume: 'https://drive.google.com/file/d/1vxxCYLvjCfanqOOa5oLRwc20u5ip0H4U/view?usp=sharing',
    campusInvolvement: 'Structural Engineers Association of California, Chinese Student Association',
    experience: [
      {year: '2020-Present', company: 'e.l.f Cosmetics', position: 'Visual Merchandising Intern'},
      {year: '2020', company: 'Bay Engineering', position: 'Drafting Assistant'},
      {year: '2020', company: 'Cal Poly Department of Architectural Engineering', position: 'Marketing Intern'},
      {year: '2019', company: 'HKIT Architects', position: 'Design Intern'},
    ],
    interestHobbies: 'Digital & Film Photography, Architecture, Design, Playing the piano, Swimming',
    funFact: 'I swam competitively for 9 years!'
  },
  { // Tyler Lin
    id: '4',
    gender: 'm',
    name: 'Tyler Lin',
    major: ['Industrial Engineering', 'IME'],
    minor: '',
    headshot: tylerlinheadshot,
    class: 'Founding',
    linkedin: 'http://linkedin.com/in/tyleralin',
    resume: 'https://drive.google.com/file/d/1Qa1CsIBsJJXH39LElhF-3z-q03Z2yNUN/view?usp=sharing',
    campusInvolvement: 'Alpha Kappa Psi, Coca-Cola Campus Ambassador, PROVE Lab',
    experience: [],
    interestHobbies: 'Photography, Fashion, Travel',
    funFact: 'I love my corgi'
  },
  { // Emma Stramberg
    id: '5',
    gender: 'f',
    name: 'Emma Stramberg',
    major: ['Biomedical Engineering', 'BMED'],
    minor: '',
    headshot: emmastrambergheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/estrambe/',
    resume: 'https://drive.google.com/file/d/1wAjgEmyDFKZLOwPLI13-6wf1Zp2dj607/view?usp=sharing',
    campusInvolvement: 'Kappa Alpha Theta, Biomedical Engineering Society, Society of Women Engineers, New Student Transition Programs',
    experience: [
      {year: '2019', company: 'TreanorHL', position: 'Student Intern'},
    ],
    interestHobbies: 'Hammocking, Hiking, Surfing, Cooking and trying out new recipes',
    funFact: 'I grew up in the middle of the United States and it has encouraged me to want to visit all the states because everywhere has something to offer'
  },
  { // Cole Marin
    id: '6',
    gender: 'm',
    name: 'Cole Marin',
    major: ['Mechanical Engineering', 'ME'],
    minor: '',
    headshot: colemarinheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/cole-marin/',
    resume: 'https://drive.google.com/file/d/1VCwO_ynfNr6nPlTFAoMJ3T4DCF9Ob1uT/view?usp=sharing',
    campusInvolvement: 'ASME, Supplemental Workshops in Science',
    experience: [
      {year: '2020', company: 'Cal Poly SLO M.E. Department', position: 'Student Researcher'},
      {year: '2019', company: 'Parker Aerospace', position: 'Quality Engineering Intern'},
    ],
    interestHobbies: 'Rock Climbing, Guitar, Motorcycles',
    funFact: 'I was born during a 7.1 magnitude earthquake!'
  },
  { //Ash Parasa
    id: '7',
    gender: 'm',
    name: 'Ash Parasa',
    major: ['Computer Science', 'CSC'],
    minor: 'Data Science',
    headshot: ashparasaheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/ashparasa',
    resume: 'https://drive.google.com/file/d/1FVyy-S_NXhbyU6AJIBf4HnTVI0jEri3n/view?usp=sharing',
    campusInvolvement: 'University Honors, Engineering Ambassadors, Data Science Fellowship, Research, Data Science Club',
    experience: [
      {year: '2021', company: 'Amazon', position: 'Software Development Intern'},
      {year: '2020', company: 'National Science Foundation', position: 'Data Science Research Fellow'},
      {year: '2020', company: 'CME Group', position: 'Software Engineering Intern'},
      {year: '2020', company: 'Harvard Medical School', position: 'Software Volunteer - COVID-19 Research'},
      {year: '2019-2020', company: 'Cal Poly Engineering', position: 'Research Assistant'},
      {year: '2019', company: 'Transitions-Mental Health Association', position: 'Full Stack Developer'},
      {year: '2019', company: 'US Army Research Laboratory', position: 'Machine Learning Research Intern'},
      {year: '2017-2018', company: 'Prudential Financial', position: 'Software Engineering Intern'},
    ],
    interestHobbies: 'Traveling, Hiking, Running, Gaming, Geography, Politics, Soccer',
    funFact: 'I learned how to code by making mods for Minecraft!'
  },
  { // Spencer Flynn
    id: '8',
    gender: 'm',
    name: 'Spencer Flynn',
    major: ['Materials Engineering', 'MATE'],
    minor: '',
    headshot: spencerflynnheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/spencer-flynn-ba72a3197/',
    resume: 'https://drive.google.com/file/d/18yzQ-kbGFesu8bPR6PQM3Phg19QSyLZG/view?usp=sharing',
    campusInvolvement: 'Future Fuels Club, Swim Club',
    experience: [],
    interestHobbies: 'Swimming, Basketball, Hiking, Reading, Golfing',
    funFact: 'I always eat all my fries before I eat my burger lol'
  },
  { // Khanhthy Le
    id: '9',
    gender: 'f',
    name: 'Khanhthy Le',
    major: ['Electrical Engineering', 'EE'],
    minor: '',
    headshot: khanhthyleheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/khanhthyle/',
    resume: 'https://drive.google.com/file/d/1UCc-MysEqG7wGsqh-DoQh_9cMU5835Ll/view?usp=sharing',
    campusInvolvement: 'Mustang Consulting, Cal Poly Hyperloop, alpha Kappa Delta Phi',
    experience: [
      {year: '2020', company: 'Mustang Consulting', position: 'Associate Consultant'},
      {year: '2018', company: 'Naprotek', position: 'Electronics Manufacturing Intern'},
    ],
    interestHobbies: 'Reading, Crafting, Learning new things!',
    funFact: 'I have two puppies from the same litter'
  },
  { // Joey Lombardo
    id: '10',
    gender: 'm',
    name: 'Joey Lombardo',
    major: ['Civil Engineering', 'CE'],
    minor: '',
    headshot: joeylombardoheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/joey-lombardo/',
    resume: 'https://drive.google.com/file/d/1RC_tVrAfdLoTjBOaz_O-ZmuymU-HqlRZ/view?usp=sharing',
    campusInvolvement: 'American Society of Civil Engineers, Steel Bridge Club, Concrete Canoe Club, Engineers Without Borders, Inter Housing Council Senate',
    experience: [
      {year: '2018', company: 'Engineering Systems Solutions', position: 'Shadow'},
    ],
    interestHobbies: 'Mountain Biking, Snowboarding, Fishing, Camping, Hiking, Soccer',
    funFact: 'I\'m nice at ping pong'
  },
  { // Anushree Parmar
    id: '11',
    gender: 'f',
    name: 'Anushree Parmar',
    major: ['Computer Engineering', 'CPE'],
    minor: '',
    headshot: anushreeparmarheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/anushree-parmar/',
    resume: 'https://drive.google.com/file/d/1Ddu5WlXosHQWXtDM95MzrsfhB482mruO/view?usp=sharing',
    campusInvolvement: 'WISH, Hack4Impact, CS + SG, CPES, aKDPhi',
    experience: [],
    interestHobbies: 'Data Analytics, Web Design, Traveling, Cooking/Baking',
    funFact: ''
  },
  { // Melvin Tabios
    id: '12',
    gender: 'm',
    name: 'Melvin Tabios',
    major: ['Civil Engineering', 'CE'],
    minor: '',
    headshot: melvintabiosheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/melvin-william-tabios-324551180/',
    resume: 'https://drive.google.com/file/d/1lnQoC3OP5akeIdqEn2nuZqu0HiecC25O/view?usp=sharing',
    campusInvolvement: 'Pilipino Cultural Exchange, United in Movement SLO',
    experience: [
      {year: '2018', company: 'Clark Construction Group', position: 'Construction Intern'},
    ],
    interestHobbies: 'Dance, Music, Basketball, Videogames',
    funFact: 'Before studying to become an engineer, for the longest time I\'ve always wanted to become a forensic scientist and help solve criminal cases. It wasn\'t until the summer before senior year of high school that I changed my mind.'
  },
  { // Vincent Chen
    id: '13',
    gender: 'm',
    name: 'Vincent Chen',
    major: ['Mechanical Engineering', 'ME'],
    minor: '',
    headshot: vincentchenheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/vincentchen415/',
    resume: 'https://drive.google.com/file/d/1f99mpoyLa3GTPV-dlwEeZe_cHnH9n3gF/view?usp=sharing',
    campusInvolvement: 'PROVE, ASME, KASA, CSA',
    experience: [],
    interestHobbies: 'Tennis, Rock Climbing, Hiking',
    funFact: 'I have a scar from chopsticks!'
  },
  { //Gabriel Hyun
    id: '14',
    gender: 'm',
    name: 'Gabriel Hyun',
    major: ['Computer Science', 'CSC'],
    minor: 'Mathematics',
    headshot: gabrielhyunheadshot,
    class: 'Founding',
    linkedin: 'https://www.linkedin.com/in/gabrielhyun/',
    resume: 'https://drive.google.com/file/d/1wqyHUrlPXhKPB-v9-AdW5Tl3oZE2Cb3R/view?usp=sharing',
    campusInvolvement: 'Korean American Student Association',
    experience: [
      {year: '2020', company: 'Automation Anywhere', position: 'Software Engineering Intern (Frontend)'},
      {year: '2019', company: 'Automation Anywhere', position: 'Technical Marketing Intern'}
    ],
    interestHobbies: 'Gaming, Reading, Web Development',
    funFact: 'When I was 10 I fell down a flight of stairs!'
  },
  { // Jordan Ross
    id: '15',
    gender: 'f',
    name: 'Jordan Ross',
    major: ['Electrical Engineering', 'EE'],
    minor: '',
    headshot: jordanrossheadshot,
    class: 'Alpha',
    linkedin: 'https://www.linkedin.com/in/jordan-c-ross/',
    resume: 'https://drive.google.com/file/d/1F4xzTJ3ibAumaPJo8MFFITWCBpd4Pm2O/view?usp=sharing',
    campusInvolvement: '',
    experience: [],
    interestHobbies: '',
    funFact: ''
  },
  { // Sabrina Nguyen
    id: '16',
    gender: 'f',
    name: 'Sabrina Nguyen',
    major: ['Electrical Engineering', 'EE'],
    minor: '',
    headshot: sabrinanguyenheadshot,
    class: 'Alpha',
    linkedin: 'https://www.linkedin.com/in/sabrinnanguyen/',
    resume: 'https://drive.google.com/file/d/1oDrK7qqXnGtlUOJXiwJhNeAlYFQPY6Lt/view?usp=sharing',
    campusInvolvement: 'Cal Poly SWE (Society of Women Engineers)',
    experience: [
    ],
    interestHobbies: 'Going to concerts, Beaches, Art Exhibits, Playing Volleyball',
    funFact: 'I would like to think that I\'m psychic because I would have dreams or essentially a third eye telling me someting about the future and boom they turn out to be true.'
  },
  { // Caroline Pham
    id: '17',
    gender: 'f',
    name: 'Caroline Pham',
    major: ['Civil Engineering', 'CE'],
    minor: '',
    headshot: carolinephamheadshot,
    class: 'Alpha',
    linkedin: 'https://www.linkedin.com/in/caroline-pham-7a7132205/',
    resume: 'https://drive.google.com/file/d/1lcsN9lcLN0pip6kRW0sgRU02kort701E/view?usp=sharing',
    campusInvolvement: 'ASCE, SWE',
    experience: [
    ],
    interestHobbies: 'Basketball, Hiking, True Crime Shows, Cooking',
    funFact: 'I can juggle!'
  },
  { //Jadon Bradford
    id: '0',
    gender: 'm',
    name: 'Jadon Bradford',
    year: '2024',
    major: ['Biomedical Engineering', 'BMED'],
    minor: '',
    headshot: jbrhs,
    class: 'Alpha',
    linkedin: 'https://linkedin.com/in/jadonb',
    resume: 'https://drive.google.com/file/d/1xwdnw7njligEKbU9O5PGanvAYPjF0bGA/view?usp=sharing',
    campusInvolvement: 'Medical Design Club: electrolyte monitoring project, BMES, Theta Tau, GSR Student Assistant',
    experience: [
    ],
    interestHobbies: 'Volleyball, Medical Devices, Hiking, Cooking, Thrifting',
    funFact: 'I have a dog named dude, I can fit his whole fist in his mouth, I\'ve lived overseas my whole life'
  },
  { //Alexis DiGregorio
    id: '1',
    gender: 'f',
    name: 'Alexis DiGregorio',
    year: '2024',
    major: ['Aerospace Engineering', 'AERO'],
    minor: '',
    headshot: adhs,
    class: 'Alpha',
    linkedin: 'https://www.linkedin.com/in/alexis-digregorio/',
    resume: 'https://drive.google.com/file/d/1WFzAoTyuBK7kty3-4gBUMCNCT8loIsvi/view?usp=sharing',
    campusInvolvement: 'Cal Poly Space Systems',
    experience: [],
    interestHobbies: 'Astronomy, Hiking, Reading',
    funFact: 'I love soccer!'
  }, 
  { //Skyler Han
    id: '2',
    gender: 'f',
    name: 'Skyler Han',
    year: '2024',
    major: ['Computer Science', 'CSC'],
    minor: '',
    headshot: shhs,
    class: 'Alpha',
    linkedin: 'https://www.linkedin.com/in/skyler-han-cp/',
    resume: 'https://drive.google.com/file/d/1HVkjaew8ctR8qPFM-ffQiEVyG7P0zvCM/view?usp=sharing',
    campusInvolvement: 'Honors Program, KASA, WISH, Kappa Alpha Theta, Hack4Impact',
    experience: [
    ],
    interestHobbies: 'Embroidery, badminton, political Netflix shows, trashy reality shows, impulsively buying house plants at Home Depot',
    funFact: 'I\'ve lived in 4 different cities in the past 3 years'
  },
  { //Carlos Lopez
    id: '3',
    gender: 'm',
    name: 'Carlos Lopez',
    year: '2024',
    major: ['Aerospace Engineering', 'AERO'],
    minor: '',
    headshot: clhs,
    class: 'Alpha',
    linkedin: 'https://www.linkedin.com/in/carlos-lopez-98656a1a8/',
    resume: 'https://drive.google.com/file/d/1W0A2ZxRbFQvJu4QAkomH0osiLdafDy4O/view?usp=sharing',
    campusInvolvement: 'NSTP (Orientation Leadership Team), Resident Advisor, SHPE, TRIO',
    experience: [
    ],
    interestHobbies: 'Listening to music, Going on walks, Crocheting, Hanging out with friends',
    funFact: 'I have 6 siblings total in my family'
  },
  { // Noelle Marroquin
    id: '4',
    gender: 'f',
    name: 'Noelle Marroquin',
    year: '2024',
    major: ['Environmental Management', 'ENVM'],
    minor: 'City and Regional Planning',
    headshot: nmhs,
    class: 'Beta',
    linkedin: 'https://www.linkedin.com/in/noellemarroquin/',
    resume: 'https://drive.google.com/file/d/1UQWksDeubOMeqglmWd710JEbNxfxDhV5/view?usp=sharing',
    campusInvolvement: 'Engineers Without Borders - Fiji Team, CP Entrepreneurs - The Intrepid, CP Cat Shelter, CP Dean of Students, CP New Student and Transition Programs',
    experience: [],
    interestHobbies: 'Sustainability, Reading, Paddleboarding',
    funFact: 'I can legally use the prefix "Lady" before my name (Lady Noelle Monique Marroquin).'
  },
  { // Xaviera Pons
    id: '5',
    gender: 'f',
    name: 'Xaviera Pons',
    year: '2024',
    major: ['Electrical Engineering', 'EE'],
    minor: '',
    headshot: xphs,
    class: 'Beta',
    linkedin: 'https://www.linkedin.com/in/xaviera-pons-725130183/',
    resume: 'https://drive.google.com/file/d/12IWH0A5DShBjLoLaV9hp71Vw1uyiQR0h/view?usp=sharing',
    campusInvolvement: 'Theta Tau',
    experience: [ {year: '2021 - Present', company: 'Cal Poly EE Dept', position: 'Lab Assistant'} ],
    interestHobbies: 'Music, Fashion, Make-up, Fitness, Journaling, Documentaries, Tech',
    funFact: 'I have been playing billiards since I was tall enough to reach the table!'
  },
  { // Tana Risley
    id: '6',
    gender: 'f',
    name: 'Tana Risley',
    year: '2024',
    major: ['Civil Engineering', 'CE'],
    minor: 'Construction Management',
    headshot: trhs,
    class: 'Beta',
    linkedin: 'https://www.linkedin.com/in/tanarisley',
    resume: 'https://drive.google.com/file/d/1ivku7SToFSTn_Z7aDwieNqVd7zmA6Ulx/view?usp=sharing',
    campusInvolvement: 'Alpha Omicron Pi, Panhellenic, Mustang Scholars, Cal Poly Cat Program',
    experience: [
      {year: '2021', company: 'Stacy and Witbeck', position: 'Office & Field Engineering Intern'}
    ],
    interestHobbies: 'Hiking, Backpacking, Surfing',
    funFact: 'I have been skydiving!'
  },
  // { // Shannon Robinson
  //   id: '7',
  //   gender: 'f',
  //   name: 'Shannon Robinson',
  //   year: '2024',
  //   major: ['Mechanical Engineering', 'ME'],
  //   minor: '',
  //   headshot: srhs,
  //   class: 'Beta',
  //   linkedin: 'https://www.linkedin.com/in/syrobins/',
  //   resume: 'https://drive.google.com/file/d/1Ir4aNjS44oeDUZPWwjvLf1JCXvtIQzP9/view?usp=sharing',
  //   campusInvolvement: 'EMPOWER; Chinese Student Association; Pilipino Cultural Exchange',
  //   experience: [{year: '2021', company: 'Avantec Vascular Corporation', position: 'Manufacturing Engineering Intern'}],
  //   interestHobbies: 'Singing, Thrifting, Martial Arts, Hiking',
  //   funFact: 'My birthday is on Christmas :)'
  // },
  { // Bella Shaffer
    id: '8',
    gender: 'f',
    name: 'Bella Shaffer',
    year: '2024',
    major: ['Aerospace Engineering', 'AERO'],
    minor: 'Spanish',
    headshot: bshs,
    class: 'Beta',
    linkedin: 'https://www.linkedin.com/in/annabella-shaffer',
    resume: '',
    campusInvolvement: 'Cal Poly Space Systems',
    experience: [],
    interestHobbies: 'Paddleboarding, Skiing, Hiking',
    funFact: 'I dislocated my shoulder doing paddleboard yoga'
  },
  { // Reese Wheeler
    id: '9',
    gender: 'f',
    name: 'Reese Wheeler',
    year: '2024',
    major: ['Biomedical Engineering | bioinstrumentation', 'BMED'],
    minor: 'gender, race, culture, science, and technology',
    headshot: rwhs,
    class: 'Beta',
    linkedin: 'https://www.linkedin.com/in/reesewheeler',
    resume: rwr,
    campusInvolvement: 'SWE, MDC, BMES',
    experience: [
      {year: '2021', company: 'Tandem Diabetes Care', position: 'Mechanical Engineering Intern'},
      {year: '2018-2020', company: 'The Engineering Enterprise', position: 'Intern'}
    ],
    interestHobbies: 'Tennis, going to the beach, watching new shows/movies, taking fun trips',
    funFact: 'I love watching reality TV'
  },
  { // Nick Ghaffarian
    id: '11',
    gender: 'm',
    name: 'Nick Ghaffarian',
    year: '2024',
    major: ['Electrical Engineering', 'EE'],
    minor: '',
    headshot: nghs ,
    class: 'Gamma',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [],
    interestHobbies: '',
    funFact: ''
  }
];
export const brothers = [
  { // Jasmine Bueno
    id: '12',
    gender: 'f',
    name: 'Jasmine Bueno',
    year: '2025',
    major: ['Biomedical Engineering', 'BMED'],
    minor: '',
    headshot: jbuhs,
    class: 'Delta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [],
    interestHobbies: '',
    funFact: ''
  },
  { // Abigail Carter
    id: '13',
    gender: 'f',
    name: 'Abigail Carter',
    year: '2026',
    major: ['Aerospace Engineering', 'AERO'],
    minor: '',
    headshot: achs,
    class: 'Delta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [],
    interestHobbies: '',
    funFact: ''
  },
  { // Raquel Fujita
    id: '14',
    gender: 'f',
    name: 'Raquel Fujita',
    year: '2026',
    major: ['Civil Engineering', 'CE'],
    minor: '',
    headshot: rfhs,
    class: 'Delta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [],
    interestHobbies: '',
    funFact: ''
  },
  { // Vanessa Guzman
    id: '15',
    gender: 'f',
    name: 'Vanessa Guzman',
    year: '2025',
    major: ['Electrical Engineering', 'EE'],
    minor: '',
    headshot: vghs,
    class: 'Delta',
    linkedin: '',
    resume: '',
    campusInvolvement: 'Systems and Optimization Club, Kappa Alpha Theta',
    experience: [],
    interestHobbies: 'Coffee, weight lifting, hiking, trying new sports, and going out with friends',
    funFact: 'I’ve taken multiple lunch breaks on Disneyland’s Space Mountain '
  },
  { // Sakiko Pizzorno
    id: '17',
    gender: 'f',
    name: 'Sakiko Pizzorno',
    year: '2026',
    major: ['Electrical Engineering', 'EE'],
    minor: '',
    headshot: sphs,
    class: 'Delta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [],
    interestHobbies: '',
    funFact: ''
  },
  { // Caden Upson
    id: '18',
    gender: 'm',
    name: 'Caden Upson',
    year: '2025',
    major: ['Computer Science', 'CSC'],
    minor: '',
    headshot: cuhs,
    class: 'Delta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [],
    interestHobbies: '',
    funFact: ''
  },
  { // Emily Vuong
    id: '19',
    gender: 'f',
    name: 'Emily Vuong',
    year: '2025',
    major: ['Industrial Engineering', 'IE'],
    minor: '',
    headshot: evhs,
    class: 'Delta',
    linkedin: '',
    resume: '',
    campusInvolvement: 'IISE (institute of industrial and systems engineers)',
    experience: [],
    interestHobbies: 'Reading, Tennis, Badminton, Ping Pong, Dance',
    funFact: 'I have a stuffed caterpillar plush as a car mascot!'
  },
  { // Jay Zavala-Albor
    id: '20', 
    gender: 'f',
    name: 'Jay Zavala-Albor',
    year: '2025',
    major: ['Environmental Engineering', 'ENVE'],
    minor: '',
    headshot: jzhs,
    class: 'Delta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [],
    interestHobbies: '',
    funFact: ''
  },
  { // Shafiq Amat
    id: '21', 
    gender: 'm',
    name: 'Shafiq Amat',
    year: '2026',
    major: ['Mechanical Engineering', 'ME'],
    minor: 'Computer Science',
    headshot: sahs,
    class: 'Epsilon',
    Board: 'True',
    Position: 'Regent',
    linkedin: 'https://www.linkedin.com/in/shafiq-amat/',
    resume: '',
    campusInvolvement: '',
    experience: [],
    interestHobbies: '',
    funFact: ''
  },
  // { // Jack Devereux
  //   id: '22', 
  //   gender: 'm',
  //   name: 'Jack Devereux',
  //   year: '2026',
  //   major: ['General Engineering', 'GE'],
  //   minor: '',
  //   headshot: jdhs,
  //   class: 'Epsilon',
  //   linkedin: '',
  //   resume: '',
  //   campusInvolvement: '',
  //   experience: [],
  //   interestHobbies: '',
  //   funFact: ''
  // },
  { // Dominique Giang
    id: '24', 
    gender: 'm',
    name: 'Dominique Giang',
    year: '2025',
    major: ['Architectural Engineering', 'ARCE'],
    minor: '',
    headshot: dghs,
    class: 'Epsilon',
    Board: 'True',
    Position: 'Vice Regent',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [],
    interestHobbies: '',
    funFact: ''
  },
  { // Ava Ngo
    id: '25', 
    gender: 'f',
    name: 'Ava Ngo',
    year: '2026',
    major: ['Civil Engineering', 'CE'],
    minor: '',
    headshot: anhs,
    class: 'Epsilon',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [],
    interestHobbies: '',
    funFact: ''
  },
   { // Izabella Parga
     id: '26',
     gender: 'f',
     name: 'Izabella Parga',
     year: '2026',
     major: ['Biomedical Engineering', 'BMED'],
     minor: '',
     headshot: iphs,
     class: 'Epsilon',
     Board: 'True',
     Position: 'Treasurer',
     linkedin: '',
     resume: '',
     campusInvolvement: 'SWE and EMPOWER',
     experience: [],
     interestHobbies: 'Prosthetics and Baseball',
     funFact: ''
     },
  { // Aiden Phan
    id: '27', 
    gender: 'm',
    name: 'Aiden Phan',
    year: '2025',
    major: ['Civil Engineering', 'CE'],
    minor: '',
    headshot: aphs,
    class: 'Epsilon',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [],
    interestHobbies: '',
    funFact: ''
  },
  { // Paige Read
    id: '28', 
    gender: 'f',
    name: 'Paige Read',
    year: '2025',
    major: ['BioMedical Engineering', 'BMED'],
    minor: '',
    headshot: prhs,
    class: 'Epsilon',
    linkedin: '',
    resume: '',
    campusInvolvement: 'EMPOWER, inCHAARG, Society of Women Engineers (SWE)',
    experience: [],
    interestHobbies: 'Volleyball, hiking, going to the beach, camping',
    funFact: 'I’ve played volleyball since I was 7!'
  },
  { // Evan Russell
    id: '29',
    gender: 'm',
    name: 'Evan Russell',
    year: '2026',
    major: ['BioMedical Engineering', 'BMED'],
    minor: '',
    headshot: erhs,
    class: 'Epsilon',
    Board: 'True',
    Position: 'Recording Secretary',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [ ],
    interestHobbies: '',
    funFact: ''
  },
  { // Jodi Yamane
    id: '30', 
    gender: 'f',
    name: 'Jodi Yamane',
    year: '2026',
    major: ['Computer science', 'CSC'],
    minor: '',
    headshot: jyhs,
    class: 'Epsilon',
    linkedin: 'http://linkedin.com/in/jodi-yamane-615708253',
    resume: jyr,
    campusInvolvement: 'Hui o Hawaii, Hack4Impact',
    experience: [],
    interestHobbies: 'Beach, music, volleyball',
    funFact: 'I did 6 sports when I was younger (not well, though)'
  },
  { // Suhanth Alluri
    id: '31',
    gender: 'm',
    name: 'Suhanth Alluri',
    year: '2026',
    major: ['Computer Science', 'CSC'],
    minor: '',
    headshot: salhs,
    class: 'Zeta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [ ],
    interestHobbies: 'Computers, Outdoors/Nature, F1',
    funFact: 'I am a Jr. Software Developer at Amazon'
  },
  { // Akhila Donthi
    id: '32',
    gender: 'f',
    name: 'Akhila Donthi',
    year: '2027',
    major: ['Mechanical Engineering', 'ME'],
    minor: '',
    headshot: '',
    class: 'Zeta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [ ],
    interestHobbies: '',
    funFact: ''
  },
  { // Arianne Landers
    id: '33',
    gender: 'f',
    name: 'Arianne Landers',
    year: '2025',
    major: ['Industrial Engineering', 'IE'],
    minor: '',
    headshot: '',
    class: 'Zeta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [ ],
    interestHobbies: '',
    funFact: ''
  },
  { // Jasmine Ng
    id: '34',
    gender: 'f',
    name: 'Jasmine Ng',
    year: '2027',
    major: ['Computer Engineering', 'CPE'],
    minor: '',
    headshot: jnhs,
    class: 'Zeta',
    linkedin: '',
    resume: '',
    campusInvolvement: 'Engineering Student Council, Hack4Impact, Cal Poly Robotics, Cal Poly Racing',
    experience: [ ],
    interestHobbies: 'Gym, hiking, pickleball',
    funFact: 'My comfort movie is Minions'
  },
  { // Nirali Patel
    id: '35',
    gender: 'f',
    name: 'Nirali Patel',
    year: '2026',
    major: ['Biomedical Engineering', 'BMED'],
    minor: '',
    headshot: '',
    class: 'Zeta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [ ],
    interestHobbies: '',
    funFact: ''
  },
  { // Renee Rebolledo
    id: '36',
    gender: 'f',
    name: 'Renee Rebolledo',
    year: '2026',
    major: ['Aerospace Engineering', 'AERO'],
    minor: '',
    headshot: '',
    class: 'Zeta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [ ],
    interestHobbies: '',
    funFact: ''
  },
  { // Maxwell Silver
    id: '36',
    gender: 'f',
    name: 'Maxwell Silver',
    year: '2026',
    major: ['Computer Science', 'CSC'],
    minor: '',
    headshot: mshs,
    class: 'Zeta',
    linkedin: '',
    resume: '',
    campusInvolvement: 'Pilipino Cultural Exchange, University Honors Program',
    experience: [ ],
    interestHobbies: 'Playing pool, learning languages, owning plants, thrifting',
    funFact: 'I met Grant Perez'
  },
  { // Will Toll
    id: '37',
    gender: 'm',
    name: 'Will Toll',
    year: '2025',
    major: ['Biomedical Engineering', 'BMED'],
    minor: '',
    headshot: wths,
    class: 'Zeta',
    Board: 'True',
    Position: 'Corresponding Secretary',
    linkedin: '',
    resume: '',
    campusInvolvement: 'oSTEM, EMPOWER, Medical Design Club',
    experience: [ ],
    interestHobbies: '',
    funFact: ''
  },
  { // Anna Wilson
    id: '38',
    gender: 'f',
    name: 'Anna Wilson',
    year: '2027',
    major: ['Mechanical Engineering', 'ME'],
    minor: '',
    headshot: '',
    class: 'Zeta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [ ],
    interestHobbies: '',
    funFact: ''
  },
  { // Alex Valenzano
    id: '39',
    gender: 'f',
    name: 'Alex Valenzano',
    year: '2026',
    major: ['Mechanical Engineering', 'ME'],
    minor: '',
    headshot: '',
    class: 'Zeta',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [ ],
    interestHobbies: '',
    funFact: ''
  }
  /*
  { // name
    id: '',
    gender: '',
    name: '',
    year: '',
    major: ['', ''],
    minor: '',
    headshot: ,
    class: '',
    linkedin: '',
    resume: '',
    campusInvolvement: '',
    experience: [ ],
    interestHobbies: '',
    funFact: ''
  }
  */
];

export default brothers && Alum;

// Will Toll = Position:'Corresponding Secretary',